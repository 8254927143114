export {
  extractCloudinaryImage,
  extractMobileCloudinaryImage,
  extractPostEntries,
  getMorePosts,
  getMorePostsQuery,
  getPost,
  getPosts,
  getPostsByProject,
  getPostsWithSlug,
  getPreviewPostBySlug,
  getRecentPosts,
} from './BlogService'
export type { BlogPost, BlogPostResponse, Project } from './BlogService'
