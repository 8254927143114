import { gql as contentfulGQL } from '@apollo/client'
import { Document } from '@contentful/rich-text-types'
import { EntryFields } from 'contentful'
import { getContentfulClient } from '@/services/ApolloClient'

export type Project = {
  blogMetaDescription: EntryFields.Text
  slug: EntryFields.Text
}

export type BlogPost = {
  cloudinaryImage: EntryFields.Object
  cloudinaryMobileImage?: EntryFields.Object
  cloudinaryImageDesktopTransformation: EntryFields.Text
  cloudinaryImageMobileTransformation: EntryFields.Text
  cloudinaryImageSocialSharingTransformation: EntryFields.Text
  content: {
    json: Document
    links: Document
  }
  excerpt: EntryFields.Text
  header: EntryFields.Text
  project: Project
  section: EntryFields.Text
  slug: EntryFields.Text
  sys: {
    firstPublishedAt: EntryFields.Date
    id: string
    publishedAt: EntryFields.Date
  }
  tags: Array<EntryFields.Text>
  title: EntryFields.Text
}

export type BlogPostResponse = {
  post: BlogPost
  morePosts: BlogPost[]
}

type PostCollectionResponse = {
  postCollection: {
    items: BlogPost[]
  }
}

type ProjectCollectionResponse = {
  projectCollection: {
    items: Project[]
  }
}

const getPostsQuery = contentfulGQL/* GraphQL */ `
  query getPosts($preview: Boolean!, $skip: Int) {
    projectCollection(limit: 1, where: { slug: "angel-studios" }) {
      items {
        blogMetaDescription
      }
    }
    postCollection(
      limit: 6
      order: sys_firstPublishedAt_DESC
      preview: $preview
      skip: $skip
      where: { hidden_not: true }
    ) {
      items {
        cloudinaryImage
        cloudinaryMobileImage
        excerpt
        header
        project {
          slug
        }
        slug
        sys {
          firstPublishedAt
          id
        }
        title
      }
      total
    }
  }
`

const getPostsByProjectQuery = contentfulGQL/* GraphQL */ `
  query getPostsByProject($preview: Boolean!, $project: String!, $skip: Int) {
    projectCollection(limit: 1, where: { slug: $project }) {
      items {
        blogMetaDescription
      }
    }
    postCollection(
      limit: 6
      order: sys_firstPublishedAt_DESC
      preview: $preview
      skip: $skip
      where: { project: { slug: $project }, hidden_not: true }
    ) {
      items {
        cloudinaryImage
        excerpt
        header
        slug
        sys {
          firstPublishedAt
          id
        }
        title
      }
      total
    }
  }
`

const getPostsWithSlugQuery = contentfulGQL/* GraphQL */ `
  query getPostsWithSlug {
    postCollection(limit: 5000, order: sys_firstPublishedAt_DESC, where: { slug_exists: true }) {
      items {
        project {
          slug
        }
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`

const getPostQuery = contentfulGQL/* GraphQL */ `
  query getPost($preview: Boolean!, $slug: String!) {
    postCollection(limit: 1, preview: $preview, where: { slug: $slug }) {
      items {
        cloudinaryImage
        cloudinaryMobileImage
        cloudinaryImageDesktopTransformation
        cloudinaryImageMobileTransformation
        cloudinaryImageSocialSharingTransformation
        content {
          json
          links {
            entries {
              block {
                sys {
                  id
                }
              }
            }
          }
        }
        excerpt
        header
        section
        slug
        sys {
          firstPublishedAt
          id
          publishedAt
        }
        tags
        title
      }
    }
  }
`

export const getMorePostsQuery = contentfulGQL/* GraphQL */ `
  query getMorePosts($preview: Boolean!, $project: String, $skip: Int!) {
    postCollection(
      limit: 6
      order: sys_firstPublishedAt_DESC
      preview: $preview
      skip: $skip
      where: { project: { slug: $project } }
    ) {
      items {
        cloudinaryImage
        cloudinaryMobileImage
        excerpt
        header
        project {
          slug
        }
        slug
        sys {
          id
          firstPublishedAt
        }
        title
      }
    }
  }
`

const getRecentPostsQuery = contentfulGQL/* GraphQL */ `
  query getRecentPosts($preview: Boolean!, $project: String!, $slug: [String]!) {
    postCollection(
      limit: 5
      order: sys_firstPublishedAt_DESC
      preview: $preview
      where: { project: { slug: $project }, slug_not_in: $slug }
    ) {
      items {
        cloudinaryImage
        cloudinaryMobileImage
        header
        slug
        sys {
          id
        }
        title
      }
    }
  }
`

const getPreviewPostBySlugQuery = contentfulGQL/* GraphQL */ `
  query getPreviewPostBySlug($slug: String!) {
    postCollection(limit: 1, preview: true, where: { slug: $slug }) {
      items {
        project {
          slug
        }
        slug
      }
    }
  }
`

const extractPost = (response: PostCollectionResponse) => {
  return response?.postCollection?.items?.[0]
}

export const extractPostEntries = (response: PostCollectionResponse) => {
  return response?.postCollection?.items
}

const extractProject = (response: ProjectCollectionResponse) => {
  return response?.projectCollection?.items?.[0]
}

export const extractCloudinaryImage = (post: BlogPost) => {
  return post?.cloudinaryImage?.[0]
}

export const extractMobileCloudinaryImage = (post: BlogPost) => {
  return post?.cloudinaryMobileImage?.[0]
}

interface GetPostsArgs {
  preview?: boolean
  skip?: number
}

export const getPosts = async ({ preview = false, skip }: GetPostsArgs) => {
  const contentfulClient = getContentfulClient({
    preview,
  })

  const { data } = await contentfulClient.query({
    query: getPostsQuery,
    variables: {
      preview,
      skip,
    },
  })

  return {
    posts: extractPostEntries(data),
    project: extractProject(data),
  }
}

interface GetPostsByProjectArgs {
  preview?: boolean
  project: string
}

export const getPostsByProject = async ({ preview = false, project }: GetPostsByProjectArgs) => {
  const contentfulClient = getContentfulClient({
    preview,
  })

  const { data } = await contentfulClient.query({
    query: getPostsByProjectQuery,
    variables: {
      project,
      preview,
    },
  })

  return {
    posts: extractPostEntries(data),
    project: extractProject(data),
  }
}

export const getPostsWithSlug = async () => {
  const contentfulClient = getContentfulClient({
    preview: false,
  })

  const { data } = await contentfulClient.query({
    query: getPostsWithSlugQuery,
  })

  return extractPostEntries(data)
}

interface GetPostArgs {
  preview?: boolean
  slug?: string | string[]
}

export const getPost = async ({ preview = false, slug }: GetPostArgs) => {
  const contentfulClient = getContentfulClient({
    preview,
  })

  const { data } = await contentfulClient.query({
    query: getPostQuery,
    variables: {
      preview,
      slug,
    },
  })

  return extractPost(data)
}

interface GetMorePostsArgs {
  preview: boolean
  project?: string | string[]
}

export const getMorePosts = async ({ preview, project }: GetMorePostsArgs) => {
  const contentfulClient = getContentfulClient({
    preview,
  })

  return contentfulClient.query({
    query: getMorePostsQuery,
    variables: {
      project,
      preview,
    },
  })
}

interface GetRecentPostsArgs {
  preview: boolean
  project?: string | string[]
  slug?: string | string[]
}

export const getRecentPosts = async ({ preview, project, slug }: GetRecentPostsArgs) => {
  const contentfulClient = getContentfulClient({
    preview,
  })

  const { data } = await contentfulClient.query({
    query: getRecentPostsQuery,
    variables: {
      project,
      slug,
      preview,
    },
  })

  return extractPostEntries(data)
}

export const getPreviewPostBySlug = async (slug: string | string[] | undefined) => {
  const contentfulClient = getContentfulClient({
    preview: true,
  })

  const { data } = await contentfulClient.query({
    query: getPreviewPostBySlugQuery,
    variables: {
      slug,
    },
  })

  return extractPost(data)
}
