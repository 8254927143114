import React from 'react'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants/paths'
import { BlogPost, extractCloudinaryImage } from '@/services/BlogService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

interface BlogCardProps {
  post: BlogPost
  project: string
}

export const BlogCard: React.FC<BlogCardProps> = React.memo(({ post, project }) => {
  return (
    <InternalLink key={post?.title} href={`${paths.blog.index}/${project || post.project.slug}/posts/${post?.slug}`}>
      <div className="cursor-pointer">
        <img
          alt={post.title}
          className="h-40 w-full object-cover lg:h-48"
          src={getCloudinaryImageUrl({
            height: 288,
            path: `v${extractCloudinaryImage(post)?.version}/${extractCloudinaryImage(post)?.public_id}.webp`,
          })}
          loading="lazy"
        />
        <h3 className="pt-6 text-2xl font-black md:text-[1.75rem]">{post?.header}</h3>
        <div className="pt-4">
          By Angel Studios |{' '}
          {new Date(post?.sys?.firstPublishedAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </div>
      </div>
    </InternalLink>
  )
})

BlogCard.displayName = 'React.memo(BlogCard)'
