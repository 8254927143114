import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { getContentfulClient } from '@/services/ApolloClient'
import { extractPostEntries, getMorePostsQuery } from '@/services/BlogService'
import type { BlogPost } from '@/services/BlogService'
import BlogGridSection from './BlogGridSection'
import BlogHeroSection from './BlogHeroSection'
import BlogHighlightSection from './BlogHighlightSection'

interface BlogViewProps {
  posts: BlogPost[]
  project?: string
}

const BlogView: React.FC<BlogViewProps> = ({ posts, project }) => {
  const client = getContentfulClient()
  const [skip, setSkip] = useState(6)
  const [loading, setLoading] = useState(false)
  const [morePosts, setMorePosts] = useState<BlogPost[]>(posts.slice(3))
  const observerRef = useRef(null)

  const loadMorePosts = useCallback(async () => {
    const { data } = await client.query({
      query: getMorePostsQuery,
      variables: { preview: false, project, skip },
    })

    if (data?.postCollection?.items.length > 0) {
      setSkip((previous) => previous + 6)
      setMorePosts((previous) => [...previous, ...extractPostEntries(data)])
    }
  }, [client, skip, project])

  useEffect(() => {
    const observerRefValue = observerRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        if (!loading) {
          const entry = entries[0]
          if (entry.isIntersecting) {
            setLoading(true)
            loadMorePosts()
            setLoading(false)
          }
        }
      },
      { rootMargin: '0px 0px 352px 0px' },
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue)
      }
    }
  }, [loading, loadMorePosts, observerRef])

  return (
    <div className="min-h-screen bg-white">
      {posts && posts?.length > 0 && (
        <>
          <BlogHeroSection post={posts[0]} project={project} />
          <PaddedContainer>
            {posts?.length > 1 && <BlogHighlightSection posts={posts.slice(1, 3)} project={project} />}
            {morePosts?.length > 0 && <BlogGridSection loading={loading} posts={morePosts} project={project} />}
          </PaddedContainer>
          <div ref={observerRef} />
        </>
      )}
    </div>
  )
}

export default BlogView
