import React from 'react'
import classNames from 'classnames'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

interface BlogHeroProps {
  alt: string
  cloudinaryImgPath: string
  cloudinaryMobileImgPath?: string
  cloudinaryImageDesktopTransformation?: string
  cloudinaryImageMobileTransformation?: string
  desktopImageClassName: string
  mobileImageClassName: string
}

const BlogHeroImage: React.FC<BlogHeroProps> = ({
  alt,
  cloudinaryImgPath,
  cloudinaryMobileImgPath,
  cloudinaryImageDesktopTransformation,
  cloudinaryImageMobileTransformation,
  desktopImageClassName,
  mobileImageClassName,
}) => {
  const mobileImgPath = cloudinaryMobileImgPath ?? cloudinaryImgPath
  return (
    <>
      <div className="absolute top-0 z-10 h-12 w-full bg-gradient-to-b from-gray-950 to-transparent sm:h-24 lg:h-32" />
      <img
        alt={alt}
        className={classNames('w-full object-cover lg:hidden', mobileImageClassName)}
        src={getCloudinaryImageUrl({
          path: mobileImgPath,
          transforms: cloudinaryImageMobileTransformation ?? 'c_fill,g_faces',
        })}
      />
      <img
        alt={alt}
        className={classNames('hidden w-full object-cover lg:block', desktopImageClassName)}
        src={getCloudinaryImageUrl({
          path: cloudinaryImgPath,
          transforms: cloudinaryImageDesktopTransformation ?? 'c_fill,g_faces',
        })}
      />
    </>
  )
}

export default BlogHeroImage
