import React from 'react'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants/paths'
import { BlogPost, extractCloudinaryImage } from '@/services/BlogService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

interface BlogHighlightSectionProps {
  posts: BlogPost[]
  project?: string
}

const BlogHighlightSection: React.FC<BlogHighlightSectionProps> = ({ posts, project }) => {
  return (
    <div className="py-10">
      <h2 className="w-fit border-b-4 pb-4 text-2xl font-bold md:text-[2rem] md:leading-8">Featured Posts</h2>
      <div className="grid grid-cols-1 gap-6 pt-8 lg:grid-cols-2">
        {posts.map((post) => {
          const projectSlug = project || post.project.slug
          return (
            <InternalLink key={post?.title} href={`${paths.blog.index}/${projectSlug}/posts/${post?.slug}`}>
              <div className="cursor-pointer">
                <img
                  alt={post?.header}
                  className="h-52 w-full object-cover sm:h-60 md:h-72"
                  src={getCloudinaryImageUrl({
                    height: 320,
                    path: `v${extractCloudinaryImage(post)?.version}/${extractCloudinaryImage(post)?.public_id}.webp`,
                  })}
                />
                <h3 className="pt-6 text-2xl font-black md:text-[1.75rem]">{post?.header}</h3>
                <div className="pt-4">{post?.excerpt}</div>
                <div className="pt-4">
                  By Angel Studios |{' '}
                  {new Date(post?.sys?.firstPublishedAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </div>
              </div>
            </InternalLink>
          )
        })}
      </div>
    </div>
  )
}

export default BlogHighlightSection
