import React from 'react'
import { LinkButton } from '@/atoms/Button'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { AsLabel, CaptionMD, HeadingSM, ParagraphSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import BlogHeroImage from '@/organisms/BlogHeroImage'
import { BlogPost, extractCloudinaryImage, extractMobileCloudinaryImage } from '@/services/BlogService'

interface BlogHeroSectionProps {
  post: BlogPost
  project?: string
}

const BlogHeroSection: React.FC<BlogHeroSectionProps> = ({ post, project }) => {
  const cloudinaryImg = extractCloudinaryImage(post)
  const cloudinaryMobileImg = extractMobileCloudinaryImage(post)
  const projectSlug = project || post.project.slug
  return (
    <div className="text-white">
      <BlogHeroImage
        alt={post?.title}
        cloudinaryImgPath={`v${cloudinaryImg?.version}/${cloudinaryImg?.public_id}.webp`}
        cloudinaryMobileImgPath={
          cloudinaryMobileImg ? `v${cloudinaryMobileImg?.version}/${cloudinaryMobileImg?.public_id}.webp` : undefined
        }
        cloudinaryImageDesktopTransformation={post?.cloudinaryImageDesktopTransformation}
        cloudinaryImageMobileTransformation={post?.cloudinaryImageMobileTransformation}
        desktopImageClassName="brightness-[0.75] lg:max-h-[56rem]"
        mobileImageClassName="brightness-[0.75] min-h-[30rem]"
      />
      <PaddedContainer className="relative">
        <PaddedContainer className="absolute bottom-4 left-0 z-10 grid grid-flow-row gap-4 sm:bottom-8 lg:bottom-16">
          <HeadingSM
            color="white"
            className="md:photon-heading-md lg:photon-heading-lg xl:photon-heading-xl 2xl:photon-display w-full sm:w-[36rem] lg:w-[48rem]"
            weight="bold"
          >
            {post?.header}
          </HeadingSM>
          <ParagraphSM
            color="white"
            className="lg:photon-paragraph-md 2xl:photon-paragraph-lg w-full sm:w-[30rem] lg:w-[42rem]"
          >
            {post?.excerpt}
          </ParagraphSM>
          <LinkButton
            data-testid="read-now-button"
            className="w-40 py-4"
            href={`${paths.blog.index}/${projectSlug}/posts/${post?.slug}`}
            variant="white"
          >
            <CaptionMD weight="bold" as={AsLabel} color="black" className="mx-auto">
              Read Now
            </CaptionMD>
          </LinkButton>
        </PaddedContainer>
      </PaddedContainer>
    </div>
  )
}

export default BlogHeroSection
