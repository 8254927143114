import React from 'react'
import { BlogCard, BlogCardPlaceholder } from '@/molecules/BlogCard'
import { BlogPost } from '@/services/BlogService'

interface BlogGridSectionProps {
  loading: boolean
  posts: BlogPost[]
  project?: string
}

const BlogGridSection: React.FC<BlogGridSectionProps> = ({ loading, posts, project }) => {
  return (
    <div className="pb-20">
      <h2 className="w-fit border-b-4 pb-4 text-2xl font-bold md:text-[2rem] md:leading-8">More Posts</h2>
      <div className="grid grid-cols-1 gap-6 pt-8 md:grid-cols-2 lg:grid-cols-3">
        {posts.map((post) => {
          return <BlogCard key={post.sys.id} post={post} project={project || post.project.slug} />
        })}
        {loading &&
          Array(6)
            .fill(0)
            .map((_, index) => <BlogCardPlaceholder key={index} />)}
      </div>
    </div>
  )
}

export default BlogGridSection
